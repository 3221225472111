/* GLOBAL */
body {
  overflow-x: hidden;
}
.display-sm {
  display: none;
}

.text-center {
  text-align: center;
}

.text-shadow {
  color: #fff;
  text-shadow: 3px 3px rgba(243, 116, 116, 0.4);
}

.btn{
  padding: 20px 40px;
  border: none;
  outline: 0;
  cursor: pointer;
}

.btn-primary {
  background-color: #6A5BE1;
  color: #fff;
  text-transform: uppercase;
  letter-spacing: 2px;
}

.container {
  max-width: 1200px;
  width: 90%;
  margin: 0 auto;
}

/* Header */
header {
  padding: 20px 0;
  overflow: hidden;
}

header .container {
  display: flex;
  justify-content: space-between;
}

header img {
  max-width: 50px;
}

header nav {
  z-index: 999;
}

header nav ul {
  display: flex;
  list-style: none;
}

header nav ul li {
  margin: 0 15px;
  cursor: pointer;
}

header nav ul li a {
  text-decoration: none;
  color: #000;
}

header .nav-toggle {
  display: none;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #c7bfff;
  width: 50px;
  height: 50px;
  z-index: 1000;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
  cursor: pointer;
}

header .nav-toggle span {
  width: 30px;
  height: 4px;
  background-color: #000;
  margin: 2px 0;
  transition: transform 0.4s ease-in-out;
  -webkit-transition: transform 0.4s ease-in-out;
  -moz-transition: transform 0.4s ease-in-out;
  -ms-transition: transform 0.4s ease-in-out;
  -o-transition: transform 0.4s ease-in-out;
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
}

header .nav-toggle.active span:nth-child(1) {
  transform: rotate(45deg) translate(3px, 2px);
  -webkit-transform: rotate(45deg) translate(3px, 2px);
  -moz-transform: rotate(45deg) translate(3px, 2px);
  -ms-transform: rotate(45deg) translate(3px, 2px);
  -o-transform: rotate(45deg) translate(3px, 2px);
}
header .nav-toggle.active span:nth-child(2) {
  display: none;
}
header .nav-toggle.active span:nth-child(3) {
  transform: rotate(-45deg) translate(2px, -4px);
  -webkit-transform: rotate(-45deg) translate(2px, -4px);
  -moz-transform: rotate(-45deg) translate(2px, -4px);
  -ms-transform: rotate(-45deg) translate(2px, -4px);
  -o-transform: rotate(-45deg) translate(2px, -4px);
}


/* Select season form */
.select-season-form {
  display: grid;
  place-content: center;
  padding: 1rem 0 2rem 0;
}

.select-season-form select {
  padding: .5rem;
  border: 1px solid #6A5BE1;
}

/* Hall of fame */
.hall-fame-tab {
  animation: fade-grow-in 0.5s ease-out forwards;
  -webkit-animation: fade-grow-in 0.5s ease-out forwards;
}

@keyframes fade-grow-in {
  from {
    opacity: 0;
    transform: scale(0.8);
    -webkit-transform: scale(0.8);
    -moz-transform: scale(0.8);
    -ms-transform: scale(0.8);
    -o-transform: scale(0.8);
  }
  to {
    opacity: 1;
    transform: scale(1);
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -ms-transform: scale(1);
    -o-transform: scale(1);
  }
}

.tab-entry-title {
  text-transform: capitalize;

}
.loading,
.error {
  padding: 20px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  text-transform: uppercase;
  letter-spacing: 2px;
}

.loading img {
  width: 250px;
}

.hall-of-fame {
  padding: 30px 0;
  background-color: #6A5BE1;
  color: #fff;
}

.hall-of-fame.all-time-stats {
    background: linear-gradient(300deg,deepskyblue,darkviolet,#6a5be1);
    background-size: 180% 180%;
    animation: gradient-animation 18s ease infinite;
}
@keyframes gradient-animation {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

#hall-of-famers-toggle {
  display: flex;
  align-items: center;
  justify-content: center;
}

#hall-of-famers-toggle > p {
  text-transform: uppercase;
  font-size: 14px;
  letter-spacing: 2px;
}

#hall-of-famers-toggle > input[type="checkbox"] {
  width: 0;
  height: 0;
  visibility: hidden;
}

#hall-of-famers-toggle > label {
  cursor: pointer;
  width: 80px;
  height: 36px;
  background: #c7bfff;
  color: #6A5BE1;
  border-radius: 20px;
  -webkit-border-radius: 20px;
  -moz-border-radius: 20px;
  -ms-border-radius: 20px;
  -o-border-radius: 20px;
  position: relative;
  margin: 0 20px;
  transition: all 0.2s ease-in-out;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
}

#hall-of-famers-toggle > label::before {
  content: "Click me";
  font-size: 10px;
  position: absolute;
  top: -15px;
  left: 50%;
  transform: translateX(-50%);
  -webkit-transform: translateX(-50%);
  -moz-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  -o-transform: translateX(-50%);
  color: #fff;
  text-transform: uppercase;
  text-align: center;
  letter-spacing: 1px;
  width: 100%;
}

#hall-of-famers-toggle > label::after {
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  content: "\f521";
  position: absolute;
  top: 3px;
  left: 3px;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center; 
  background-color: #fff;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
  transition: all 0.2s  ease-in-out;
  -webkit-transition: all 0.2s  ease-in-out;
  -moz-transition: all 0.2s  ease-in-out;
  -ms-transition: all 0.2s  ease-in-out;
  -o-transition: all 0.2s  ease-in-out;
}

#hall-of-famers-toggle > input[type="checkbox"]:checked + label::after {
  content: "\f1e3";
  left: calc(100% - 33px);
}

#hall-of-famers-toggle > input[type="checkbox"]:checked + label {
  background: #f3c5c5;
}

.members {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: 1fr;
  max-width: 800px;
  margin: 50px auto;
}

.member {
  grid-row: 1/2;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  border: 1px solid #fff;
  padding: 20px 0;
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
  overflow: hidden;
  transition: 0.4s ease-in;
  -webkit-transition: 0.4s ease-in;
  -moz-transition: 0.4s ease-in;
  -ms-transition: 0.4s ease-in;
  -o-transition: 0.4s ease-in;
}

.member.rank-1:hover {
  transform: scale(1.1);
  -webkit-transform: scale(1.1);
  -moz-transform: scale(1.1);
  -ms-transform: scale(1.1);
  -o-transform: scale(1.1);
}

.member.rank-1 { /* Rank 1*/
  grid-column: 2/3;
}

.member.rank-2 {/*Rank3*/
  grid-column: 1/2;
}

.member.rank-3 {/*Rank2*/
  grid-column: 3/4;
}

.member.rank-2,
.member.rank-3 {
  transform: scale(0.8);
  -webkit-transform: scale(0.8);
  -moz-transform: scale(0.8);
  -ms-transform: scale(0.8);
  -o-transform: scale(0.8);
}

.member.rank-2:hover,
.member.rank-3:hover {
  transform: scale(0.9);
  -webkit-transform: scale(0.9);
  -moz-transform: scale(0.9);
  -ms-transform: scale(0.9);
  -o-transform: scale(0.9);
}

.member .crown {
  position: absolute;
  top: 5px;
  right: 10%;
  transform: rotate(43deg);
  -webkit-transform: rotate(43deg);
  -moz-transform: rotate(43deg);
  -ms-transform: rotate(43deg);
  -o-transform: rotate(43deg);
}

.member .crown img {
  width: 50px;
  height: auto;
}

.member img.profile-pic {
  display: inline-block;
  width: 200px;
  height: 200px;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
  object-fit: cover;
  object-position: center top;
  margin-bottom: 20px;
}

.member.member-big img.profile-pic {
  animation: pulse 2s linear infinite;
  -webkit-animation: pulse 2s linear infinite;
}

@keyframes pulse {
  0% {
    -webkit-box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.4);
  }
  100% {
      -webkit-box-shadow: 0 0 0 20px rgba(255, 255, 255, 0);
  }
}

.member small {
  text-transform: uppercase;
  font-weight: 600;
  letter-spacing: 2px;
}

.member .member-goals,
.member .wins {
  font-size: 36px;
  width: 80px;
  height: 80px;
  background-color: rgba(255, 255, 255, 0.6);
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
  display: grid;
  place-content: center;
  margin: 20px 0;
  font-weight: bold;
}

.member .hall-of-famer {
  text-transform: uppercase;
}

.member .hall-of-famer-rank {
  position: absolute;
  top: 0;
  left: 0;
  color: #000;
  width: 40px;
  height: 80px;
  display: grid;
  place-content: center;
}

.member .hall-of-famer-position {
  font-weight: bold;
  font-size: 26px;
  position: relative;
  margin: 0;
  transform: translateY(-10px);
  z-index: 1;
  -webkit-transform: translateY(-10px);
  -moz-transform: translateY(-10px);
  -ms-transform: translateY(-10px);
  -o-transform: translateY(-10px);
}

.member .hall-of-famer-rank-bg {
  position: absolute;
  top: 0;
  left: 0;
  width: 80px;
  height: 80px;
  background-color: #fff;
  z-index: 0;
  clip-path: polygon(0 0, 0% 100%, 100% 0);
}

/* Player Visuals */
.visual-block {
  padding: 25px 0 0;
  margin: 50px 0 0;
  background: #fff;
}

.player-visuals-grid {
  padding: 50px 0;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 20px;
}

.player-visuals-card {
  flex-basis: calc(100% / 3);
  padding: 40px;
  box-shadow: inset 0px 15px 10px -15px rgba(106, 91, 225, 0.45), 0px 15px 10px -15px rgba(106, 91, 225, 0.65);
  border: 1px solid rgba(106, 91, 225, 0.1);
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
}

.player-visuals-card-inner {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}

.player-visuals-card-inner > div {
  padding: 10px;
}

.player-visuals-card {
  text-align:center;
}

.player-goal-conversion-visual span:nth-child(1) {
  color: #6A5BE1;
}

.player-goal-conversion-visual span:nth-child(2) {
  color: #F37474;
}
/* Display Players */
.players-table {
  padding: 50px 0 0;
  background-color: rgba(106, 91, 225, 0.2);
}

.players-titles,
.player {
  display: flex;
}

.players-titles p,
.player p {
  flex: 1;
}

.players-titles {
  font-weight: bold;
  text-transform: uppercase;
  letter-spacing: 2px;
  padding: 10px 0px 0px 30px;
}

.player {
  background-color: #fff;
  border-radius: 20px;
  -webkit-border-radius: 20px;
  -moz-border-radius: 20px;
  -ms-border-radius: 20px;
  -o-border-radius: 20px;
  overflow: hidden;
}

.player {
  margin-top: 20px;
}

.player.mia {
  background-color: #fcd6d6;
  border: 2px solid red;
}

.player-first {
  margin-top: 0;
}

.player-position {
  font-size: 40px;
  font-weight: bold;
  padding: 10px;
  background-color: #6A5BE1;
  color: #fff;
}

.player-position.player-position-in-question {
  font-size: 14px;
}

.player p {
  margin: 0;
  padding: 20px;
  line-height: 60px;
  font-weight: bold;
}

.player p:not(.player-position) {
  font-size: 18px;
}

/* Add Tournament */
.new-tournament {
  background-color: #E1DEF9;
  padding: 20px 0;
}

/* .won-by {
  display: flex;
} */

.player-stats {
  margin: 30px 0;
  padding: 30px;
  border-radius: 20px;
  -webkit-border-radius: 20px;
  -moz-border-radius: 20px;
  -ms-border-radius: 20px;
  -o-border-radius: 20px;
  background-color: #fff;
}

.new-tournament .field {
  margin: 10px 0;
}

.player-stats legend {
  font-size: 24px;
  font-weight: bold;
}

.player-stats {
  display: flex;
  justify-content: space-between;
}

.player-stats.fields-disabled input[type="text"],
.player-stats.fields-disabled input[type="number"] {
  cursor: disabled;
  pointer-events: none;
  opacity: 0.3;
}

.player-stats .field label,
.player-stats .field input {
  display: block;
}

.new-tournament .field label {
  font-weight: bold;
}

.field input[type="number"],
.field input[type="password"],
.field input[type="text"] {
  border: 1px solid #6A5BE1 !important;
  border: none;
  outline: 0;
  padding: 10px 20px;
  margin-top: 10px;
  display: block;
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
}

@media(max-width: 768px) {
  .members {
    display: unset;
  }

  .members .member {
    margin: 20px 0;
  }

  .members .member.member-small {
    transform: scale(1);
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -ms-transform: scale(1);
    -o-transform: scale(1);
  }

  .player-visuals-grid {
    grid-template-columns: 1fr 1fr;
  }
}

@media(max-width: 500px) {
  .display-lg {
    display: none;
  }

  .display-sm {
    display: inline-block;
  }

  nav {
    position: absolute;
    top: 0;
    right: -250px;
    width: 250px;
    height: 100%;
    background-color: #fff;
    padding-top: 20vh;
    transition: all 0.4s ease-in-out;
    -webkit-transition: all 0.4s ease-in-out;
    -moz-transition: all 0.4s ease-in-out;
    -ms-transition: all 0.4s ease-in-out;
    -o-transition: all 0.4s ease-in-out;
  }

  nav.active {
    right: 0;
  }

  nav ul {
    flex-direction: column;
  }

  header .nav-toggle {
    display: flex;
  }

  .member .crown {
    right: 21%;
  }

  .player p {
    padding: 10px;
  }

  .player-position {
    font-size: 26px;
  }

  .player-visuals-grid {
    grid-template-columns: 1fr;
  }
}